import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/src/simplebar.css';
import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
import "./globals.css";
import "./index.css";
import './locales/i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SoftUIControllerProvider } from './softcontext';
const root = ReactDOM.createRoot(document.getElementById('root'));

// ----------------------------------------------------------------------
ReactGA.pageview(window.location.pathname + window.location.search);

root.render(
  <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <BrowserRouter>
            <SoftUIControllerProvider>
              <App />
            </SoftUIControllerProvider>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
  </HelmetProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
