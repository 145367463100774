import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Icon } from '@iconify/react';
import { Container, Divider, IconButton, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoFooter from '../../components/LogoFooter';
import SoftTypography from '../../components/SoftTypography';
import useLocales from '../../hooks/useLocales';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { translate } = useLocales();
  const location = useLocation();

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" >
        {location.pathname !== '/' && (
          <Stack key="kontakt" spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignText: 'center' }}>
            <SoftTypography color="primary" textGradient variant="h4" fontWeight="bold">
              {translate('Kontakta oss')}
            </SoftTypography>

            <IconButton
              key={"linkedin"}
              color="primary"
              sx={{ p: 0, pl: 0 }}
              href={"https://www.linkedin.com/company/workplacerai"}
              target="_blank"
              rel="noreferrer"
              aria-label={"linkedin"}
            >
              <Icon icon={linkedinFill} width={16} height={16} />
            </IconButton>
            <Stack key="kontaktunder" spacing={0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignText: 'center' }}>
              <Link
                href="mailto:hze@sioeberga.se"
                key="hze@sioeberga.se"
                target="_blank"
                rel="noreferrer"
                color="inherit"
                variant="body2"
                sx={{ display: 'block' }}
              >
                hze@sioeberga.se
              </Link>
              <Link
                href="mailto:nan@sioeberga.se"
                key="nan@sioeberga.se"
                target="_blank"
                rel="noreferrer"
                color="inherit"
                variant="body2"
                sx={{ display: 'block' }}
              >
                nan@sioeberga.se
              </Link>
              <Link
                href="https://maps.app.goo.gl/kyeSbb5Kftx9vW1h9"
                target="_blank"
                rel="noreferrer"
                key="gmaps"
                color="inherit"
                variant="body2"
                sx={{ display: 'block' }}
              >
                Sjöberga Gård, Björsäter
              </Link>
            </Stack>
          </Stack>
        )}
        <LogoFooter sx={{ mt: 2, mx: { xs: 'auto', md: 'inherit' } }} />

        <Typography
          component="p"
          variant="body2"
          sx={{
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'center' }
          }}
        >
          © 2024. Sjöberga Gård
        </Typography>
      </Container>
    </RootStyle>
  );
}
