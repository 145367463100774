import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import book from '@iconify/icons-ic/book';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import cameraFill from '@iconify/icons-eva/camera-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Hem',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'Om Sjöberga Gård',
    path: PATH_PAGE.modules,
    icon: <Icon icon={cameraFill} {...ICON_SIZE} />
  },
  {
    title: 'Aktiviteter',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.howitworks
  },
  {
    title: 'Våra Upplägg',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing
  },
  {
    title: 'Bilder',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.images
  },
  {
    title: 'Kontakt',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.contact
  }
];

export default menuConfig;
