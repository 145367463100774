import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from 'notistack';
import theme from "./assets/theme";
import { ProgressBarStyle } from './components/LoadingScreen';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import GlobalStyles from './theme/globalStyles';

// ----------------------------------------------------------------------

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        <GlobalStyles />
        <ProgressBarStyle />
        <ScrollToTop />
        <SnackbarProvider />
        <Router />
      </>

    </ThemeProvider>
  );
}

export default App;
