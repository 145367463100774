// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_API = 'https://plejsapp-api.herokuapp.com/api/v1';

// ----------------------------------------------------------------------

export const PATH_API = {
  root: ROOTS_API,
  spaces: path(ROOTS_API, '/spaces/index'),
  places: path(ROOTS_API, '/places/index'),
  placesrecent: path(ROOTS_API, '/places/recent')
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  home: '/',
  modules: '/modules',
  howitworks: '/how-it-works',
  pricing: '/pricing',
  activities: '/activities',
  about: '/about',
  contact: '/contact',
  images: '/images',
  page404: '/404',
  page500: '/500',
  comingsoon: '/comingsoon',
  blog: {
    root: '/blog/posts',
    posts: '/blog/posts',
    post: '/blog/post/',
    newPost: '/blog/new-post'
  }
};


export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
